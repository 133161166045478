import { StoreAction } from "../../@core/constants/action";

const initialState = {
  reduxNotification: [],
  n_last_id: 0,
};
export default function (state = initialState, action) {
  switch (action.type) {
    // case StoreAction.GET_ALL_NOTIFICATION_DATA:
    //   return {
    //     ...state,
    //     reduxNotification: action.payload.notification,
    //     n_last_id: action.payload.n_last_id,
    //   };

    // case StoreAction.REMAINING_NOTIFICATION:
    //   return {
    //     ...state,
    //     reduxNotification: action.payload.notification,
    //     n_last_id: action.payload.n_last_id,
    //   };

    // case StoreAction.READ_ALL_NOTIFICATION:
    //   return {
    //     ...initialState,
    //   };
    default:
      return state;
  }
}
