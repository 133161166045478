// ** Reducers Imports
import navbar from "../navbar";
import layout from "../layout";
import auth from "../authentication";
import commonReducer from "./commonReducer";
import notificationReducer from "./notificationReducer";

const rootReducer = {
  auth,
  navbar,
  layout,
  commonReducer,
  notificationReducer,
};

export default rootReducer;
