import { StoreAction } from "../../@core/constants/action";

const initialState = {
  is_loading: false,
  is_note_loading: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    // case StoreAction.SET_IS_LOADING:
    //   return {
    //     ...state,
    //     is_loading: action.payload,
    //   };
    // case StoreAction.SET_IS_NOTES_LOADING:
    //   return {
    //     ...state,
    //     is_note_loading: action.payload,
    //   };
    default:
      return state;
  }
}
